import Vue from 'vue'
import Router from 'vue-router'

// const ListView = () => import('../views/list/index.vue')
// const ItemView = () => import('../views/item/index.vue')
// const UserView = () => import('../views/user/index.vue')

const DirectoryView = () => import('../components/Directory.vue');
const ListingView = () => import('../components/Listing.vue');
const HomeView = () => import('../views/pages/home.vue');
const HomeUpdateView = () => import('../views/pages/home.vue');
const TestView = () => import('../components/super/EstablishmentHours.vue');
const Editor = () => import('../components/Editor.vue');
const excelUpload = () => import('../components/excelupload.vue');

const SuperEntries = () => import('../components/super/SuperEntries.vue');
const SuperDirectories = () => import('../components/super/SuperDirectories.vue');
const SuperEntriesEntryUpdate = () => import('../components/super/SuperEntriesEntryUpdate.vue');
const SuperEntriesCreate = () => import('../components/super/SuperEntriesCreate.vue');
const SuperListingsCreateType = () => import('../components/super/SuperListingsCreateType.vue');
const SuperListingsBulkCreateType = () => import('../components/super/SuperListingsBulkCreateType.vue');
const SuperCategories = () => import('../components/super/SuperCategories.vue');
const SuperCategoriesCategoryEdit = () => import('../components/super/SuperCategoriesCategoryEdit.vue');
const SuperCategoriesCreateType = () => import('../components/super/SuperCategoriesCreateType.vue');
const Super = () => import('../components/super/Super.vue');

Vue.use(Router)




const routes = [
  // { path: '/directory/:section', component: DirectoryView },
  { path: '/listing/:id?', component: ListingView },
  { path: '/search', component: DirectoryView },
  { path: '/editor', component: Editor },
  { path: '/search/:cat', component: DirectoryView },
  { path: '/', component: HomeView },
  { path: '/testing/joseph', component: TestView },
  { path: '/super/listings/create', component: SuperEntriesCreate },
  { path: '/super/pages/create', component: SuperEntriesCreate },
  { path: '/super/pages/:page/update', component: SuperEntriesCreate },
  { path: '/super/listings/create/:type', component: SuperListingsCreateType },
  { path: '/super/listings/bulk/create/:type', component: SuperListingsBulkCreateType },
  { path: '/super/entries', component: SuperEntries },
  { path: '/super/directories', component: SuperDirectories },
  { path: '/super/listings/:id', component: SuperEntriesEntryUpdate },
  { path: '/super/categories', component: SuperCategories },
  { path: '/uploadexcel', component: excelUpload  },
  { path: '/super/categories/:category/edit', component: SuperCategoriesCategoryEdit },
  { path: '/super/categories/create/:taxonomy', component: SuperCategoriesCreateType },
  { path: '/super', component: Super },
  // { path: '/uploadexcel', component: excelUpload  }

  // { path: '/item/:id(\\d+)', component: ItemView },
  // { path: '/user/:id', component: UserView },
  // { path: '/:type/:page(\\d+)?', component: ListView },
]

// routes.forEach((item) => {
//   const { meta } = item
//   let { path } = item
//   let baseDir = '/vue-directory'

//   if (meta && meta.project) {
//     baseDir = `/${meta.project}`
//   }

//   if (path === '/home') {
//     path = `${baseDir}/`
//   } else if (path === '/' && item.redirect) {
//     item.redirect = `${baseDir + item.redirect}/`
//   } else {
//     path = `${baseDir + path}`
//   }

//   item.path = path
// })


export default function createRouter () {
  return new Router({
    mode: 'history',
    fallback: false,
    routes,
    scrollBehavior(to, from, savedPosition) {
      console.log('scrollBehavior', to, from, savedPosition);
      if (savedPosition !== null) {
        console.log('scrollBehavior returning')
        return savedPosition;
      }
      // Only scroll to the top of the page if we are
      // changing pages (not just query params)
      if (to.path !== from.path) {
        return { x: 0, y: 0 }
      }
      // if (savedPosition) {
      //   return savedPosition
      // } else {
      //   console.log('no saved position')
        
      // }
    },
    
  })
}
