import Vue from 'vue'

export default {
  TOGGLE_LOGIN_POPUP(state) {
    state.showLoginPopup = !state.showLoginPopup;
  },
  CATEGORY_DROPDOWN: (state, display) => {
    console.log('cat dropdown', display);
    state.placesDropdown = display;
  },
  PLACES_DROPDOWN: (state, display) => {
    state.categoryDropdown = display;
  },
  SET_ACTIVE_TYPE: (state, { type }) => {
    state.activeType = type
  },

  SET_LIST: (state, { type, ids }) => {
    state.lists[type] = ids
  },

  SET_ITEMS: (state, { items }) => {
    items.forEach((item) => {
      if (item) {
        Vue.set(state.items, item.id, item)
      }
    })
  },

  SET_DIRECTORY: (state, { directory, header }) => {
    console.log('SET_DIRECTORY', directory)
    const basicDirectory = directory[0];
    const sponsoredDirectory = directory[1];

    Vue.set(state, 'directory', basicDirectory || false) /* false means user not found */
    Vue.set(state, 'sponsoredDirectory', sponsoredDirectory);
    Vue.set(state, 'header', header);
    

    // console.log(state);
    
  },

  SET_DIRECTORY_METADATA: (state, { data }) => {
    Vue.set(state, 'dirMetaData', data);
    console.log('set dir meta')
  },

  SET_DIRECTORY_SEARCH_OPTIONS: (state, { searchOptions }) => {
    console.log('SET_DIRECTORY_SEARCH_OPTIONS called', searchOptions);
    console.log(decodeURIComponent(searchOptions.directoryQuery))
    Vue.set(state, 'searchOptions', searchOptions);

    console.log('SET_DIRECTORY_SEARCH_OPTIONS finished');
    // Vue.set(state, 'directoryQuery', )

    
    // console.log('SET_DIRECTORY_SEARCH_OPTIONS', state.searchOptions);
  },
  // SET_ENTRY: (state, {data}) => {
  //   Vue.set(state, 'entry', data);
  // },
  SET_DIRECTORY_PAGE_DATA: (state, {data}) => {
    Vue.set(state, 'directoryPageData', data);
  },

  LOADING: (state, loading) => {
    Vue.set(state, 'loading', loading);
  },

  SET_SIMPLE_USER_FORM(state, {simpleUserForm}) {
    state.simpleUserForm = simpleUserForm;
  },
  SET_USER(state, user) {
    console.log('SET_USER', user);
    state.user = user
  },
  LOGIN(state, user) {
    state.user = user
  },
  // https://laracasts.com/discuss/channels/laravel/saving-an-intervention-image-instance-into-amazon-s3
  SET_LISTING(state, {data}) {
    state.listing = data;
  },
  SET_ENTRY_FORM: (state, {entryForm}) => {
    console.log('SET_ENTRY_FORM', entryForm, state.user);
    state.entryForm = entryForm;
  },
  SET_ENTRY: (state, entry) => {
    console.log('SET_ENTRY_FORM', entry, state.user);
    state.entry = entry;
  },
  SET_USER_FORM: (state, {userForm}) => {
    console.log('SET_USER_FORM', userForm);
    state.userForm = userForm;
  },
  SET_STATE(state, {key, data}) {
    state[key] = data;
  }
  // SET_LATLNG: (state, { latlng }) => {
  //   Vue.set(state, 'latlng', )
  // },
}
