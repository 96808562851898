<template>
  <svg width="12" height="12" viewBox="0 0 12 12">
    <path d="M.566 1.698L0 1.13 1.132 0l.565.566L6 4.868 10.302.566 10.868 0 12 1.132l-.566.565L7.132 6l4.302 4.3.566.568L10.868 12l-.565-.566L6 7.132l-4.3 4.302L1.13 12 0 10.868l.566-.565L4.868 6 .566 1.698z"></path>
  </svg>
</template>

<script>
  export default {
    name: 'close',
  }
</script>
