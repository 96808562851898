<template>
    <div class="container" id="app">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card card-default">
                    <div class="card-header">File Upload Component</div>
                    <div class="card-body">
                       <p class="urip"></p>
                        <div id="form" >
                               <label>File
                              <input type="file" name="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
                              </label>
                           <button v-on:click="submitFile()">upload</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import axios from 'axios'
  import {ENV_URL, MAIN_URL, STRIPE_PUBLISHABLE} from '../environment.js';
export default {
      data(){
        return {
          file:null
        }
      },
      methods:{
        handleFileUpload(){
          this.file = this.$refs.file.files[0];
          console.log(`the file is `)
          console.dir(this.file)
        },
        submitFile(){
          let formData = new FormData();
          if(this.file !== null){
            formData.append('excelSheet',this.file)
          }
          let headers = {'ContentType':'multipart/form-data'};
           const uri = ENV_URL + this.$route.path;
          
         
           this.sendFile(uri,formData,headers)
          
        },

        sendFile(destination,data,headers){
          console.log(`send through axios ${destination}`)
          axios.post(destination,data,headers)
          .then( ()=>console.log("upload was successful") )
          .catch(()=>console.log("could not upload file!"))
        }

      }
    }
</script>