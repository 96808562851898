import createAPI from './create-api'
import algoliasearch from 'algoliasearch';
import directoryAPI from './directory-api';
import axios from 'axios';
import {API_URL, ENV_URL} from '../environment.js';

    // new webpack.ProvidePlugin({
    //   URLSearchParams: ['url', 'URLSearchParams'],
    // }),
/* eslint-disable arrow-body-style, no-empty-pattern */
export default {
  // ensure data for rendering given list type
  FETCH_LIST_DATA: ({ commit, dispatch }, { type }) => {
    commit('SET_ACTIVE_TYPE', { type })
    return createAPI({
      url: `${type}stories.json`,
      retData: true,
    }).then(ids => commit('SET_LIST', { type, ids }))
      .then(() => dispatch('ENSURE_ACTIVE_ITEMS'))
  },

  // ensure all active items are fetched
  ENSURE_ACTIVE_ITEMS: ({ dispatch, getters }) => {
    return dispatch('FETCH_ITEMS', {
      ids: getters.activeIds,
    })
  },

  FETCH_ITEM: ({}, { id }) => {
    console.log('FETCH_ITEM', id)
    return createAPI({
      url: `item/${id}.json`,
      retData: true,
    })
  },

  FETCH_ITEMS: ({ commit, dispatch, state }, { ids }) => {
    console.log('FETCH_ITEMS', ids)
    // on the client, the store itself serves as a cache.
    // only fetch items that we do not already have, or has expired (3 minutes)
    const now = Date.now()
    let list = ids
    list = list.filter((id) => {
      const item = state.items[id]
      if (!item) {
        return true
      }
      if (now - item.__lastUpdated > 1000 * 60 * 3) {
        return true
      }
      return false
    })
    if (list.length) {
      return Promise.all(list.map((id) => {
        return new Promise((resolve) => {
          return dispatch('FETCH_ITEM', {
            id,
          }).then(item => resolve(item))
        })
      })).then(items => commit('SET_ITEMS', { items }))
    }
    return Promise.resolve()
  },


  FETCH_USER: ({ commit, state }, { id }) => {
    return createAPI({
      url: `user/${id}.json`,
      retData: true,
    }).then(user => commit('SET_USER', { id, user }))
  },


  FETCH_DIRECTORY: ({ commit, dispatch, state }, { route }) => {

    const query = route.query;

    // If there is a category set in the param, I have to also
    // add it to the currentlySelectedFacets object, so that Algolia
    // correctly filters.
    const params = route.params;

    console.log('route params', params);

    const hasParam = Object.keys(route.params).length > 0;

    // Get the settings for this directory search from the query
    console.log('FETCH_DIRECTORY ACTION', route, 'state', state, 'params', params);


    let searchOptions = Object.assign({}, state.searchOptions);

    // facetsForAlgolia and facetsToShow will eventually come from database
    var facetsForAlgolia = ['category','awards','sub_category', 'section', 'hawards.lvl0', 'hawards.lvl1', 'hcategory.lvl0', 'hcategory.lvl1', 'hcategory.lvl2', 'price_range', 'rating'];

    searchOptions.facetsForAlgolia = facetsForAlgolia;

    let facetsToShow = {};
    facetsToShow.restaurants = {
      'hcategory' : 'Category',
      // 'hawards.lvl0' : 'Awards',
    }
    facetsToShow.select = {
      'category' : 'Category',
      'sub_category' : 'Sub Category',
    }
    facetsToShow.spas = {
      'category' : 'Category',
      'awards' : 'Awards',
    }

    facetsToShow.default = {
      'category' : 'Category',
      'hawards.lvl0' : 'Awards',
    }

    searchOptions.facetsToShow = facetsToShow;

   var facetsForAlgolia = searchOptions.facetsForAlgolia;

    let currentlySelectedFacets = {};

    // currentlySelectedFacets[facet.taxonomy] = [facet.category];
    // Loop through all the possible facets (hcategory.lvl0, hawards.lvl0, etc)
    // and use the values from the query to update the currentlySelectedFaets
    facetsForAlgolia.map(function(facet) {
      
      if (query[facet]) {

        
        const categories = query[facet].split(',');
        console.log('query facet', query[facet], facet, categories)
        let catArray = [];
        categories.map(function(category) {
          catArray.push(category);
        })
        currentlySelectedFacets[facet] = catArray;
      }

    })

    // If a parameter is present, use it as the main category filter
    // for Algolia
    // state.searchOptions.mainFacet is hcategory.lvl0
    if (route.params && route.params.cat) {
      currentlySelectedFacets[state.searchOptions.mainFacet] = [route.params.cat];
    }

    searchOptions.currentlySelectedFacets = currentlySelectedFacets;

    if (query['page']) {
      searchOptions.page = query['page'];
    } else {
      searchOptions.page = 1;
    }

    // If there is a search in the query, use it, else, reset the
    // query params 
    if (query['q']) {
      searchOptions.directoryQuery = query['q'];
    } else {
      searchOptions.directoryQuery = '';
    }

    // If there is a lat in the query, use it, else use what's in the
    // search options. Else, if there's nothing in the searchOptions,
    // use Morristown's coordinates
    if (query['lat']) {
      searchOptions.lat = query['lat'];
      searchOptions.lng = query['lng'];
    } else if (searchOptions.lat) {
      // Don't overwrite it
    } else {
      searchOptions.lat = '40.797';
      searchOptions.lng = '-74.4809';
    }

    
    commit('SET_DIRECTORY_SEARCH_OPTIONS', { searchOptions });


    // if (to === undefined && Object.keys(state.route.query).length === 0) {
    //   to = state.route.from;
    // }
    
    // return directoryAPI({searchOptions}).then(directory => commit('SET_DIRECTORY', { directory }));



    const directory = directoryAPI({searchOptions});


    // var directoryPage = new Promise(function(resolve, reject){

    //   axios.get('https://nominatim.openstreetmap.org/reverse?format=json&lat=' + searchOptions1.lat + '&lon=' + searchOptions1.lng + '&zoom=11&addressdetails=1')
    //   .then(function(data) {
    //     resolve(data);
    //   })

    // })

    // const directoryPage = {};
    // if (hasParam) {
      // var directoryPage = new Promise(function(resolve, reject){
      //   const uri = ENV_URL + '/pages/' + route.params.cat;
      //   return axios.get(uri)
      //   .then(function(data) {
      //     // console.log('directoryPage res', data);
      //     // const page = data.data.page;
      //     resolve(data);
      //   })
  
      // })


      

      var directoryPage = new Promise(function(resolve, reject){
        return dispatch('GET_DIR_PAGE', route).then((data) => {
          resolve(data);
        })
      })






      // const uri = ENV_URL + '/pages/' + route.params.cat;
      // var directoryPage = axios.get(uri)
      // .then(function(data) {
      //   // console.log('directoryPage res', data);
      //   // const page = data.data.page;
      //   resolve(data);
      // });
    // } else {
    //   const directoryPage = {};
    // }



    // const allPromises = Promise.all([directory, directoryPage]);

    return Promise.all([directory, directoryPage]).then((response) => {

      let directoryRes = response[0];

      let directoryPageRes = response[1];


      // console.log('directoryPageRes', directoryPageRes, directoryPageRes.data.page)

      let header = {};

      if (directoryPageRes.data) {
        header = directoryPageRes.data.page;
      }

      // commit('SET_STATE', {key: 'page', data: page });

      // commit('SET_DIRECTORY_PAGE', { page: directoryPageRes });

      commit('SET_DIRECTORY', { directory: directoryRes, header:  header});

    })
  },
  

  GET_DIR_PAGE({commit}, route) {
    const uri = ENV_URL + '/pages/' + route.params.cat;
   console.log(uri);
    return axios.get(uri)
    .then(function(data) {
      // console.log('directoryPage res', data);
      // const page = data.data.page;
      // resolve(data);
      return data;
    })
  },

  FETCH_SEARCH_OPTIONS_SERVER: ({commit, state}, { to }) => {

    const searchOptions1 = {};

    var reverseIpToLocation = new Promise(function(resolve, reject){

      axios.get('https://nominatim.openstreetmap.org/reverse?format=json&lat=' + searchOptions1.lat + '&lon=' + searchOptions1.lng + '&zoom=11&addressdetails=1')
      .then(function(data) {
        resolve(data);
      })

    })

    const allPromises = Promise.all([reverseIpToLocation, searchOptions1]);

    return allPromises.then((response) => {

      let reverseIpToLocationRes = response[0];

      let searchOptions = response[1];

      let displayLoc = '';

      if (reverseIpToLocationRes.data.address.city) {
        displayLoc = reverseIpToLocationRes.data.address.city;
      } else {
        displayLoc = reverseIpToLocationRes.data.address.state;
      }

      searchOptions.userCity = displayLoc;

      commit('SET_DIRECTORY_SEARCH_OPTIONS', { searchOptions });

      return searchOptions;

    })

  },
  TOGGLE_LOGIN_POPUP({commit}) {
    console.log('TOGGLE_LOGIN_POPUP')
    commit('TOGGLE_LOGIN_POPUP');
  },
  FETCH_LISTING: ({commit, state}, { slug }) => {
    var promise1 = new Promise(function(resolve, reject){

      var uri = API_URL + '/api/listings/' + slug;
   console.log(uri);
      axios.get(API_URL + '/api/listings/' + slug)
      .then(function(response) {
        console.log('ajax res', response.data.listing);
        const listing = response.data.listing;
        resolve(listing);
      })

    })

    return promise1.then(function(data) {
      // console.log('resolved data', data);
      commit('SET_LISTING', { data });
    })
  },

  // FETCH_DIRECTORY_PAGE_DATA: ({commit, state}, { slug }) => {
  //   var promise1 = new Promise(function(resolve, reject){

  //     axios.get(API_URL + '/get_item_from_wp_js/' + slug)
  //     .then(function(response) {
  //       console.log('ajax res', response.data.json_object);
  //       const listing = JSON.parse(response.data.json_object);
  //       resolve(listing);
  //     })

  //   })

  //   return promise1.then(function(data) {
  //     // console.log('resolved data', data);
  //     commit('SET_LISTING', { data });
  //   })
  // },

  TOGGLE_LOADING: ({commit}, { loading }) => {
    commit('LOADING', !loading);
  },


  LOADING: ({commit}, loading) => {
    commit('LOADING', loading);
  },

  SUPER_ENTRIES_ENTRY_UPDATE({commit}, {uri}) {
	console.log(uri);
    return axios.get(uri)
    .then((response) => {
      console.log('response', response);
      let entryForm = response.data.form;
      let entry = response.data.listing;
      let categories = response.data.categories;
      
      // Populate entryForm with entry data
      entryForm.fields.map(function(field, index) {
  

        // console.log('field', index);
  
        if (field.type === 'field') {
          field.value = entry[field.name];
        } else if (field.type === 'meta' && entry.meta[field.name]) {
          field.value = entry.meta[field.name].value;
        } else if (field.type === 'image') {
          field.image = entry.image;
        }

  
      })
      
      commit('SET_STATE', {key: 'categories', data: categories});
      commit('SET_ENTRY_FORM', { entryForm });
      commit('SET_ENTRY', entry);
    })
    .catch(function (error) {
      console.log('app error: ', error);
    })
  },


  SUPER_ENTRIES_CREATE_TYPE({commit}, {uri}) {
   console.log(uri);
   return axios.get(uri)
    .then((response) => {
      console.log('response', response);
      let entryForm = response.data.form;
      let categories = response.data.categories;
      let entry = response.data.listing;

      if (entry) {
        // Populate entryForm with entry data
        entryForm.fields.map(function(field, index) {

          if (field.type === 'field') {
            field.value = entry[field.name];
          } else if (field.type === 'meta' && entry.meta[field.name]) {
            field.value = entry.meta[field.name].value;
          } else if (field.type === 'image') {
            field.image = entry.image;
          }

    
        })
      }

      commit('SET_ENTRY_FORM', { entryForm });
      commit('SET_STATE', {key: 'categories', data: categories});
      commit('SET_ENTRY', entry);

    })
    .catch(function (error) {
      console.log('app error: ', error);
    })
  },

  SUPER_CATEGORIES_CATEGORY({commit}, uri) {
	console.log(uri);
    return axios.get(uri)
    .then((response) => {
      console.log('response', response);
      let entryForm = response.data.form;
      let category = response.data.category;
      let categories = response.data.categories;
      let currentCat = response.data.currentCat;

      // Populate entryForm with entry data
      entryForm.fields.map(function(field, index) {
  
        if (field.type === 'field') {
          field.value = currentCat[field.name];
        }

      });
      // selectedSingleCategories
      let selectedSingleCategories = {};
      selectedSingleCategories['category'] = category;
      commit('SET_STATE', {key: 'category', data: currentCat});
      commit('SET_ENTRY_FORM', { entryForm });
      commit('SET_STATE', {key: 'selectedSingleCategories', data: selectedSingleCategories});
      commit('SET_STATE', {key: 'selectSingleCategories', data: categories});

    })
    .catch(function (error) {
      console.log('app error: ', error);
    })
  },

  SUPER_CATEGORIES_CREATE_TYPE({commit}, uri) {
	console.log(uri);
    return axios.get(uri)
    .then((response) => {
      let entryForm = response.data.form;
      let categories = response.data.categories;

      commit('SET_ENTRY_FORM', { entryForm });
      commit('SET_STATE', {key: 'selectSingleCategories', data: categories});
      // commit('SET_STATE', {key: 'categories', data: categories});

    })
    .catch(function (error) {
      console.log('app error: ', error);
    })
  },
  GET_USER({commit}) {
    let uri = ENV_URL + '/getUser';
    if (typeof window !== 'undefined') {
      var laravelAuthToken = localStorage.getItem('laravelAuthToken');
      let authHeaders = {
        headers: {"Authorization" : `Bearer ${laravelAuthToken}`}
      };

	console.log(uri);
      axios.get(uri, authHeaders)
      .then((response) => {
        const user = response.data;
        commit('SET_USER', user)
      });
    }

  },
  SET_USER_FORM: ({commit}, { userForm }) => {

    commit('SET_USER_FORM', { userForm });

  },
  LOGOUT_USER({commit}) {
    let uri = ENV_URL + '/r_logout';
    if (typeof window !== 'undefined') {
      var laravelAuthToken = localStorage.getItem('laravelAuthToken');
      let authHeaders = {
        headers: {"Authorization" : `Bearer ${laravelAuthToken}`}
      };

	console.log(uri);
      axios.get(uri, authHeaders)
      .then((response) => {
        console.log('logout res: ', response);
        localStorage.removeItem('laravelAuthToken');
        const user = null;
        commit('SET_USER', null)
      });
    }

  },
  POST_LOGIN({commit}, {login_info}) {
    let uri = ENV_URL + '/post_login';
	console.log(uri);
    return axios.post(uri, login_info).then((response) => {
      console.log('login res: ', response, login_info);

        if (response.data == false) {
            alert('Your email or password was incorrect. Please try, again.');
            return;
        }

        if (typeof window !== "undefined") {
          localStorage.setItem('laravelAuthToken', response.data.laravelAuthToken);
        }
        
        const user = response.data.user;
        commit('SET_USER', user);
    });
  },
  GET_SIMPLE_USER_FORM: ({commit}, uri) => {

    var promise1 = new Promise(function(resolve, reject){

	console.log(uri);
      axios.get(uri)
      .then(function(response) {
        console.log('ajax res', response.data);
        const data = response.data;
        resolve(data);
      })

    })

     
    return promise1.then(function(data) {
      let simpleUserForm = data;
      commit('SET_SIMPLE_USER_FORM', { simpleUserForm });
    })


  },
  CONTESTS_CONTEST_ENTRIES_CREATE: ({ commit, dispatch}, { uri }) => {
    console.log('CONTESTS_CONTEST_ENTRIES_ENTRY_COMPLETE', uri);
    var promise1 = new Promise(function(resolve, reject){

	console.log(uri);
      axios.get(uri)
      .then(function(response) {
        console.log('ajax res', response.data);
        const data = response.data;
        resolve(data);
      })

    })


    return promise1.then(function(data) {
      let entryForm = data.entryForm;
      let userForm = data.userForm;
      let page = data.page;
      let contest = data.contest;
      let addonForm = data.addonForm;

      // console.log('resolved data', data);
      commit('SET_ENTRY_FORM', { entryForm });
      commit('SET_PAGE', { page });
      commit('SET_CONTEST', { contest });
      // The userForm and addonForm are specific to the
      // complete page, but setting them here makes the
      // user experience much more smooth.
      commit('SET_USER_FORM', { userForm });
      commit('SET_ADDON_FORM', { addonForm });
    })
  },
  ENTRY_FORM: ({ commit, dispatch}, { entryForm }) => {
    commit('SET_ENTRY_FORM', { entryForm });
  },
  SET_STATE({commit}, {key, data}) {
    commit('SET_STATE', {key: key, data: data});
  }
}
