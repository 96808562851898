<template>
<svg width="16" height="16" x="0px" y="0px"
	 viewBox="0 0 450 550">
    <g>
        <g>
            <path d="M473.418,449.285L303.28,279.148c59.759-73.087,48.954-180.779-24.132-240.538S98.369-10.344,38.61,62.742
                S-10.344,243.521,62.742,303.28c62.953,51.473,153.453,51.473,216.406,0l170.138,170.138c6.78,6.548,17.584,6.36,24.132-0.42
                C479.805,466.384,479.805,455.899,473.418,449.285z M171.218,307.751c-75.37-0.085-136.449-61.163-136.533-136.533
                c0-75.405,61.128-136.533,136.533-136.533s136.533,61.128,136.533,136.533S246.623,307.751,171.218,307.751z"/>
        </g>
    </g>
</svg>
</template>

<script>
  export default {
    name: 'search-icon',
  }
</script>
